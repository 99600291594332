
export default () => {
  const countries = useState("countries", () => []);
  const cities = useState("cities", () => []);
  const districts = useState("districts", () => []);
  const towns = useState("towns", () => []);

  const getCountries = async () => {
    const data = await $fetch("/api/geo/country");

    countries.value = data ? data : [];

    return data;
  };

  const getCities = async (countryCode) => {
    const data = await $fetch("/api/geo/city", {
      query: {
        selectedCountry: countryCode,
      },
    });

    cities.value = data ? data : [];

    return data;
  };

  const getDistricts = async (city) => {
    const data = await $fetch("/api/geo/district", {
      query: {
        selectedCity: city,
      },
    });

    districts.value = data ? data : [];

    return data;
  };

  const getTowns = async (cityId, district) => {
    const data = await $fetch("/api/geo/town", {
      query: {
        selectedDistrict: district,
        cityId : cityId
      },
    });

    towns.value = data ? data : [];

    return data;
  };

  return {
    getCountries,
    getCities,
    getDistricts,
    getTowns,
    countries,
    cities,
    districts,
    towns,
  };
};
